import { CacheUtil } from './cache';

import axios, { AxiosResponse } from 'axios';
import { Pipes } from './pipes';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { RequestApiEnum } from '../enums/request-api.enum';
import { Utils } from './utils';

const okStatusCodes = [200, 201];

/**
 * Helper Function which verifies if the __jsession stored key exists and then
 * put the jwt token into options.headers
 * @param options
 * @returns options with authorization header if jwt exists
 */
const injectToken = (options: json) => {
  // TODO: supporting cart token
  const jwt = CacheUtil.cache('__jsession');
  if (!jwt) {
    return options;
  }
  const storeId = CacheUtil.cache('x-store');
  options.headers = options.headers || {};
  options.headers['Authorization'] = 'Bearer ' + jwt;

  if (storeId) {
    options.headers['x-store'] = storeId;
  }

  return options;
};

const axiosData = async <T>(
  promise: Promise<AxiosResponse>,
  Class: ClassConstructor<T>,
  interceptor?: (result: json) => any
) => {
  const result = await promise;
  if (!result || !okStatusCodes.includes(result.status)) {
    throw new Error(`${result.status}: ${result.statusText}`);
  }
  const responseIntercepted = typeof interceptor === 'function' ? interceptor(result.data) : result.data
  return Pipes.transform(Class, responseIntercepted) as T;
};

/**
 * Class with request utils.
 * This is a wrapper for axios module
 */
export class RequestUtil {
  /**
   * Wrapper function for axios.get. This function inject the jwt if exists
   * @param url
   * @param options
   * @param type
   * @param interceptor
   * @returns {Promise<*>}
   * @throws Error
   */
  static async get<T>(
    url: string, options: json | undefined = undefined,
    type: () => ClassConstructor<T>,
    interceptor?: (result: json) => any
    ) {
    return axiosData(axios.get(`${RequestApiEnum.BASE_API_PATH}${Utils.slugFromUrl()}${url}`, injectToken(options || {})), type(), interceptor);
  }

  /**
   * Wrapper function for axios.post. This function inject the jwt if exists
   * @param url
   * @param params
   * @param options
   * @param type
   * @returns {Promise<*>}
   * @throws Error
   */
  static async post<T>(
    url: string, params: json, options = {}, type: () => ClassConstructor<T>) {
    return axiosData(axios.post(`${RequestApiEnum.BASE_API_PATH}/${Utils.slugFromUrl()}${url}`, params, injectToken(options || {})),
      type());
  }

  /**
   * Wrapper function for axios.put. This function inject the jwt if exists
   * @param url
   * @param params
   * @param options
   * @param type
   * @returns {Promise<*>}
   * @throws Error
   */
  static async put<T>(
    url: string, params: json, options = {}, type: () => ClassConstructor<T>) {
    return axiosData(axios.put(`${RequestApiEnum.BASE_API_PATH}/${Utils.slugFromUrl()}${url}`, params, injectToken(options || {})),
      type());
  }

  /**
   * Wrapper function for axios.patch. This function inject the jwt if exists
   * @param url
   * @param params
   * @param options
   * @param type
   * @returns {Promise<*>}
   * @throws Error
   */
  static async patch<T>(
    url: string, params: json, options = {}, type: () => ClassConstructor<T>) {
    return axiosData(axios.patch(`${RequestApiEnum.BASE_API_PATH}/${Utils.slugFromUrl()}${url}`, params, injectToken(options || {})),
      type());
  }

  /**
   * Wrapper function for axios.delete. This function inject the jwt if exists
   * @param url
   * @param options
   * @param type
   * @returns {Promise<*>}
   * @throws Error
   */
  static async delete<T>(
    url: string, options: json, type: () => ClassConstructor<T>) {
    return axiosData(axios.delete(`${RequestApiEnum.BASE_API_PATH}/${Utils.slugFromUrl()}${url}`, injectToken(options || {})), type());
  }
}
