import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RequestUtil } from './utils';
import { LinksDto } from './context/dto';
import { IAppContext } from './context';

async function initialContext(): Promise<IAppContext> {
  const links = await RequestUtil.get('/links', {}, () => LinksDto).
    then(response => response).
    catch(error => {
      console.error(error);
      return undefined;
    });
  return {links};
}

initialContext().then((appContext: IAppContext) => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  root.render(
    <React.StrictMode>
      <App links={appContext.links}/>
    </React.StrictMode>,
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});