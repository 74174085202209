import React from 'react';
import { LinksDto } from './dto';

export interface IAppContext {
  links?: LinksDto;
  setLinks?: Function;
}

const AppContext = React.createContext({} as IAppContext);

export default AppContext;
